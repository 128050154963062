<div class="register-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="register-image">
        <img src="assets/img/register-bg.jpg" alt="image" />
      </div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
      <div class="register-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="register-form">
              <div class="logo">
                <a routerLink="/"
                  ><img src="assets/img/black-logo.png" alt="image"
                /></a>
              </div>
              <h3>Open up your Ednuv Account now</h3>
              <p>Already signed up? <a routerLink="/sign-in">Log in</a></p>
              <form>
                <div class="form-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email address"
                    class="form-control"
                    #userEmail
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Create a password"
                    class="form-control"
                    #userPwd
                    required
                  />
                </div>
                <button
                  type="button"
                  (click)="emailSignup(userEmail.value, userPwd.value)"
                >
                  Sign Up
                </button>
                <div class="connect-with-social">
                  <span>Or</span>
                  <button type="button" class="google" (click)="googleSignup()">
                    <i class="bx bxl-google"></i> Connect with Google
                  </button>
                  <!-- <button type="submit" class="twitter">
                    <i class="bx bxl-twitter"></i> Connect with Twitter
                  </button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
