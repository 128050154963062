<div class="login-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-image">
        <img src="assets/img/login-bg.jpg" alt="image" />
      </div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="login-form">
              <div class="logo">
                <a routerLink="/"
                  ><img src="assets/img/black-logo.png" alt="image"
                /></a>
              </div>
              <h3>Welcome back</h3>
              <p>New to Ednuv? <a routerLink="/register-user">Sign up</a></p>
              <form>
                <div class="form-group">
                  <input
                    type="email"
                    #userName
                    required
                    placeholder="Your email address"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    #userPassword
                    required
                    placeholder="Your password"
                    class="form-control"
                  />
                </div>
                <button
                  type="button"
                  (click)="emailSignIn(userName.value, userPassword.value)"
                >
                  Login
                </button>
                <div class="forgot-password">
                  <a (click)="openDialog()">Forgot Password?</a>
                </div>
                <div class="connect-with-social">
                  <!-- <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Google</button> -->
                  <button type="submit" class="google" (click)="googleSignin()">
                    <i class="bx bxl-google"></i> Connect with Google
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
