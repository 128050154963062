<div class="become-instructor-partner-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="become-instructor-partner-content bg-color">
          <h2>Become an Instructor</h2>
          <p>
            Choose from hundreds of free courses, or get a degree or certificate
            at a breakthrough price. Learn at your own pace.
          </p>
          <a routerLink="/sign-in" class="default-btn"
            ><i class="bx bx-plus-circle icon-arrow before"></i
            ><span class="label">Apply Now</span
            ><i class="bx bx-plus-circle icon-arrow after"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="become-instructor-partner-image bg-image1">
          <img src="assets/img/become-instructor.jpg" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="become-instructor-partner-image bg-image2">
          <img src="assets/img/become-partner.jpg" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="become-instructor-partner-content">
          <h2>Become a Partner</h2>
          <p>
            Choose from hundreds of free courses, or get a degree or certificate
            at a breakthrough price. Learn at your own pace.
          </p>
          <a routerLink="/sign-in" class="default-btn"
            ><i class="bx bx-plus-circle icon-arrow before"></i
            ><span class="label">Contact Us</span
            ><i class="bx bx-plus-circle icon-arrow after"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
