<div class="forgot-password">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>Reset Password</h3>

      <p class="text-center">
        Please enter your email address to request a password reset.
      </p>

      <div class="formGroup">
        <input
          type="email"
          class="form-control"
          placeholder="Email Address"
          #passwordResetEmail
          required
        />
      </div>

      <!-- Calling ForgotPassword from AuthService Api -->
      <div class="formGroup">
        <input
          type="submit"
          class="btn btnPrimary"
          value="Reset Password"
          (click)="onForgotPasswordClick(passwordResetEmail.value)"
        />
      </div>
    </div>

    <div class="redirectToLogin">
      <span
        >Go back to ?
        <span class="redirect" (click)="closeDialog()" routerLink="/sign-in"
          >Log In</span
        ></span
      >
    </div>
  </div>
</div>
