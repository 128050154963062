<!-- <app-hometwo-main-banner></app-hometwo-main-banner> -->

<app-homeseven-main-banner></app-homeseven-main-banner>

<div class="partner-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>כל אלה מגייסים ג'וניורים</h2>
    </div>
    <app-partner-style-two></app-partner-style-two>
  </div>
</div>

<app-jobs-container></app-jobs-container>

<!-- <app-hometwo-about></app-hometwo-about>

<div class="courses-categories-area bg-image pt-100 pb-70">
  <div class="container">
    <div class="section-title text-start">
      <span class="sub-title">Courses Categories</span>
      <h2>Browse Trending Categories</h2>
      <a routerLink="/courses-category-style-1" class="default-btn"
        ><i class="bx bx-show-alt icon-arrow before"></i
        ><span class="label">View All</span
        ><i class="bx bx-show-alt icon-arrow after"></i
      ></a>
    </div>
    <app-categories-style-one></app-categories-style-one>
  </div>
</div>

<div class="funfacts-area pt-100">
  <div class="container">
    <div class="funfacts-inner">
      <app-funfacts></app-funfacts>
    </div>
  </div>
</div>

<app-hometwo-courses></app-hometwo-courses>

<app-our-mission></app-our-mission>

<div class="partner-area bg-color ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Our Company & Partners</h2>
    </div>
    <app-partner-style-one></app-partner-style-one>
  </div>
</div>

<div class="instructor-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <span class="sub-title">Make Connections</span>
      <h2>Team of Instructors</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip
        ex ea.
      </p>
    </div>
    <app-instructors-style-two></app-instructors-style-two>
  </div>
</div>

<app-students-feedback-form></app-students-feedback-form>
-->
<div class="blog-area pt-100 pb-70">
  <div class="container">
    <div class="section-title text-start">
      <span class="sub-title">Explore News</span>
      <h2>Our Latest Insights</h2>
      <a routerLink="/blog-style-1" class="default-btn"
        ><i class="bx bx-book-reader icon-arrow before"></i
        ><span class="label">Read All</span
        ><i class="bx bx-book-reader icon-arrow after"></i
      ></a>
    </div>
    <app-blog></app-blog>
  </div>
</div> 

<app-footer></app-footer>

<!-- <app-webinar-countdown></app-webinar-countdown> -->

