<div class="banner-wrapper">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="wrapper-content">
          <h1>עבודות לג'וניורים בהייטק ובשיווק / פרסום / מכירות</h1>
          <p>
            אנחנו יודעים כמה זה קשה להיות ג'וניור ולחפש עבודה, היינו שם לא כזה
            מזמן. אז בואו ותמצאו את העבודה שמתאימה לכם בדיוק
          </p>
          <form>
            <input
              type="text"
              class="input-search"
              placeholder="What do you want to learn?"
            />
            <button type="button">Search</button>
          </form>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="banner-courses-category">
          <ul>
            <li>
              <a routerLink="/">
                <i class="bx bx-shape-triangle"></i>
                פרונט אנד
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-font-family"></i>
                בקאנד
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bxs-drink"></i>
                פול סטאק
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-first-aid"></i>
                QA
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-desktop"></i>
                פרודקט
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-bar-chart-alt-2"></i>
                שיווק
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-briefcase-alt-2"></i>
                Media Buying
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-book-reader"></i>
                FB \ IG Campaign
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="bx bx-target-lock"></i>
                מכירות
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
