import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit {
  //get course details
  // create course schema
  // get course lessons

  constructor() {}

  ngOnInit(): void {}
}
