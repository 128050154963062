<app-login-page></app-login-page>
<!-- 
<div class="displayTable">
  <div class="displayTableCell">
    <div class="px-logo">
      <a href="https://www.positronx.io" target="_blank">
        <img
          src="https://www.positronx.io/wp-content/themes/positronx/img/logo-positronx-white.svg"
          alt="positronX.io - Learn Full Stack Development"
        />
      </a>
    </div>

    <div class="authBlock">
      <h3>Sign In</h3>
      <div class="formGroup">
        <input
          type="text"
          class="formControl"
          placeholder="Username"
          #userName
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          #userPassword
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="button"
          class="btn btnPrimary"
          value="Log in"
          (click)="authService.SignIn(userName.value, userPassword.value)"
        />
      </div>

      <div class="formGroup">
        <span class="or"><span class="orInner">Or</span></span>
      </div>

      <div class="formGroup">
        <button
          type="button"
          class="btn googleBtn"
          (click)="authService.GoogleAuth()"
        >
          <i class="fab fa-google-plus-g"></i>
          Log in with Google
        </button>
      </div>

      <div class="forgotPassword">
        <span routerLink="/forgot-password">Forgot Password?</span>
      </div>
    </div>

    <div class="redirectToLogin">
      <span
        >Don't have an account?<span
          class="redirect"
          routerLink="/register-user"
        >
          Sign Up</span
        ></span
      >
    </div>
  </div>
</div> -->
