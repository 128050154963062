<div class="countdown-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="countdown-content">
          <h2>Hurry Up! Join Laravel Development Webinar in Dubai</h2>
          <a routerLink="/sign-in" class="sign-up-btn"
            >Sign Up <i class="bx bx-log-in"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="countdown-timer text-center">
          <div id="timer">
            <div id="days">{{ days }} <span>Days</span></div>
            <div id="hours">{{ hours }} <span>Hours</span></div>
            <div id="minutes">{{ minutes }} <span>Minutes</span></div>
            <div id="seconds">{{ seconds }} <span>Seconds</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
